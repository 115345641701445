@import "bootstrap/scss/bootstrap";

/* @font-face {
  font-family: 'Play';
  src: url('Play-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: 'Saira Condensed';
  src: url('Saira_Condensed/SairaCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */



.field_with_errors .message {
    margin-top: 5px;
    margin-bottom: 5px
}

.editor {
    width: 100%;
    height: 100%;
}

#react-paginate ul {
    display: inline-block;
}
  
#react-paginate li {
    display: inline-block;
}

.user_patch {
    position: fixed;
    right: 2px;
    top: 4px;
}

.dropdown-toggle {
    color: white;
}

.side_bar_collapsed {
    position: fixed;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
    width: 75px;     /* Set the width of the sidebar */
    z-index: 2;      /* Stay on top of everything */
    top: 3.4em;      /* Stay at the top */
    background-color: #343a40; /* Black */
    overflow-x: hidden;     /* Disable horizontal scroll */
    padding-top: 10px;
}

.side_bar_expanded {
    position: fixed;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
    width: 450px;     /* Set the width of the sidebar */
    z-index: 2;      /* Stay on top of everything */
    top: 3.4em;      /* Stay at the top */
    background-color: #343a40; /* Black */
    overflow-x: hidden;     /* Disable horizontal scroll */
    padding-top: 10px;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 18px;
    top: 36px;
    margin-top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #292b2c;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #292b2c;
    padding: 0 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #292b2c;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: #292b2c;
    height: 100%;
    top: 0;
  }

  .intro-title {
      opacity: 0;
      background: grey;
      transition-property: opacity;
      transition-duration: 2s;
      transition-delay: 2s;
      transition-timing-function: ease;
      animation-fill-mode: forwards;
  }

.fade-enter {
    opacity: 0;
    transition: opacity 2s ease;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 2s ease;
}

.fade-exit {
    opacity: 1;
    transition: opacity 2s ease;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 2s ease;
}

.center-item {
  text-align: center;
}

.title-text {
  size: 20px;
}

.swarm-banner {
  font-family: 'Saira Condensed', sans-serif;
}

.video-responsive {
  // overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.standard-link {
  color: black;
}

.info-text {
  font-size: 20px;
}




.landing {
  display: flex;
  flex-direction: column;

}

.video-background {
  width:100%;
  /* height:100vh; */
  /* height: 900px; */
  min-height: 600px;
  max-height: 1000px;
  overflow: hidden;
  /* min-width: 1500px;
  min-height: 400px; */
  position: relative;
  margin-top: 0px;
  
}

.video-foreground {
  z-index: -1;
  /* min-width: 1/2; */
  max-width: 100%;

  left: 0;
  object-fit: contain;
  position: absolute;
  opacity: 0.3;

}

.competeVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.3;
  pointer-events: none;
  position: absolute;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  /* center items */
  justify-content: center;
  justify-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  // margin-left: 50px;
  justify-content: center;
  justify-items: center;
  padding-left: 100px;
}

.gradient-overlay {
  position: absolute;

  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* margin-left: auto;
  margin-right: auto; */
  /* min-height: 600px;
  max-height: 1100px; */
  /* height: 500px ; */
  /* object-fit: fill; */
  background: linear-gradient(to right, #000000 30%, #ffffff);
  opacity: 0.98;
  
}

.title {
  color: white;
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 180px;
  font-size: 55px;
  font-family: 'Roboto Slab', serif;
}

.small-page-title {
  color: black;
  font-size: 55px;
  font-family: 'Roboto Slab', serif;
}

.subtitle {
  color: white;
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 250px;
  font-size: 17px;
  font-family: 'Roboto Slab', serif;
}
.subtitle2 {
  color: white;
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 275px;
  font-size: 16px;
  font-family: 'Roboto Slab', serif;
}


/* transparent button with white text and white border */
.startbutton {
  background-color: transparent;
  border: 2px solid white;
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 350px;
  color: white;
  font-family: 'Roboto Slab', serif;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  width: 180px;
  height: 50px;
  cursor: pointer;
  transition: 0.4s;
}

.downicon {
  position: absolute;
  z-index: 5;
  color: white;
  bottom: 20px;
  /* increase size and center */
  font-size: 20px;
  align-self: center;
}

.startbutton:hover {
  background-color: white;
  color: black;
}


.descriptions {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 20px;
  margin: 20px;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.descriptions p {
  font-size: 17px;
  font-family: 'Roboto Slab', serif;
  color: #000000;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.description {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Slab', serif;
  padding-left: 50px;
  padding-right: 50px;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  position: relative;
  gap: 20px;
  margin: 20px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.descriptiontitle {
  color: black;
  font-size: 30px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  /* margin-top: 50px;
  margin-bottom: 20px; */
}


.detailsbutton {
  background-color: transparent;
  border: 2px solid black;
  position: relative;
  /* left: 20px; */
  color: black;
  font-family: 'Roboto Slab', serif;
  font-size: 10px;
  text-align: center;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  cursor: pointer;
  transition: 0.4s;
}

.developerbutton {
  background-color: transparent;
  border: 2px solid darkred;
  position: relative;
  /* left: 20px; */
  color: black;
  font-family: 'Roboto Slab', serif;
  font-size: 10px;
  text-align: center;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  cursor: pointer;
  transition: 0.4s;
}

.non-detailsbutton {
  background-color: transparent;
  border: 2px solid black;
  /* left: 20px; */
  color: black;
  font-family: 'Roboto Slab', serif;
  font-size: 10px;
  text-align: center;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  cursor: pointer;
  transition: 0.4s;
}

.detailsbutton:hover {
  background-color: black;
  color: white;
  padding: 12px;
  padding-left: 25px;
  padding-right: 25px;
  /* make font bold */
  font-weight: bold;

  
}

.competebutton {
    background-color: transparent;
    border: 2px solid white;
    position: absolute;
    z-index: 3;
    left: 20px;
    top: 350px;
    color: white;
    font-family: 'Roboto Slab', serif;
    font-size: 15px;
    text-align: center;
    padding: 10px;
    width: 180px;
    height: 50px;
    cursor: pointer;
    transition: 0.4s;
  }

.competebutton:hover {
    background-color: white;
    color: black;
    transition-duration: 0.7s;
  }




.functionality {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* box-shadow: lightslategrey; */
  /* round edge */
  border-radius: 10px;
  border-color: #000000;
  /* width: 35rem; */
  /* flex-basis: 1/3; */
  margin-bottom: 20px;
  /* fixed height  */
  /* height: 350px; */
  /* shadow small */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.functionality-developer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* box-shadow: lightslategrey; */
  /* round edge */
  border-radius: 10px;
  border-color: red;
  /* width: 35rem; */
  /* flex-basis: 1/3; */
  margin-bottom: 20px;
  /* fixed height  */
  /* height: 350px; */
  /* shadow small */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.detailmedia {
  /* display: flex;
  flex-direction: column; */
  align-items: center;
  text-align: center;
  position: relative;
  width: 90%;
  height: 440px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-color: white;
}

.functionality.img{
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.functionality h2 {
  color: black;
  font-size: 30px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  // padding-top:30px;
}

.functionality p {
  font-family: 'Roboto Slab', serif;
  padding-left: 50px;
  padding-right: 50px;
  height: 120px;
  padding-bottom: 70px;
}


.h1 {
  font-family: 'Roboto Slab', serif;
}
.h2 {
  font-family: 'Roboto Slab', serif;
}
.h3 {
  font-family: 'Roboto Slab', serif;
}

@media screen and (max-width:773px){
  .pure-u-3-24 {
    display:none;
  }
}

@media screen and (min-width:773px){
  .non-pure-u-3-24 {
    display:none;
  }
}